import Dictionaries from "./Dictionaries";
import { BabyBodyPositions, BabyHeadPositions } from "./Enums";
import Global from "./Global";

export default class SVGImagePositioning {
  // About the acronyms: they are related to fetal positioning or belly mapping
  // http://www.earthboundtherapeutics.com/blog/fetal-positioning-aka-belly-mapping
  // Gets the initial position coordinates for baby body images based on position and rotation (Cephalic or Breech)
  // Returns an object with x,y coordinates
  static getBabyBodyImagesInitialPosition = (
    position: string,
    rotation: string,
    synclitism: string
  ) => {
    let posX = 0;
    let posY = 0;
    if (position === BabyBodyPositions.Cephalic) {
      if (synclitism === BabyHeadPositions.Centered) {
        switch (rotation) {
          case "LOA1":
            return { posX: 68, posY: 262 };
          case "LOA2":
            return { posX: 50, posY: 262 };
          case "LOT":
            return { posX: 65, posY: 262 };
          case "LOP4":
            return { posX: 63, posY: 262 };
          case "LOP5":
            return { posX: 66, posY: 262 };
          case "OP":
            return { posX: 74, posY: 262 };
          case "ROP7":
            return { posX: 81, posY: 262 };
          case "ROP8":
            return { posX: 85, posY: 262 };
          case "ROT":
            return { posX: 82, posY: 262 };
          case "ROA10":
            return { posX: 93, posY: 262 };
          case "ROA11":
            return { posX: 80, posY: 262 };
          default:
            // Position X & Y for the default image in Hodeleie
            return { posX: 68, posY: 262 };
        }
      } else if (synclitism === BabyHeadPositions.FrontAsynclitic) {
        switch (rotation) {
          case "LOA1":
            return { posX: 68, posY: 266 };
          case "LOA2":
            return { posX: 60, posY: 262 };
          case "LOT":
            return { posX: 63, posY: 264 };
          case "LOP4":
            return { posX: 67, posY: 266 };
          case "LOP5":
            return { posX: 68, posY: 264 };
          case "ROP7":
            return { posX: 75, posY: 265 };
          case "ROP8":
            return { posX: 80, posY: 265 };
          case "ROT":
            return { posX: 85, posY: 264 };
          case "ROA10":
            return { posX: 85, posY: 264 };
          case "ROA11":
            return { posX: 75, posY: 265 };
        }
      } else if (synclitism === BabyHeadPositions.BackAsynclitic) {
        switch (rotation) {
          case "LOA1":
            return { posX: 68, posY: 263 };
          case "LOA2":
            return { posX: 63, posY: 264 };
          case "LOT":
            return { posX: 67, posY: 264 };
          case "LOP4":
            return { posX: 70, posY: 264 };
          case "LOP5":
            return { posX: 69, posY: 264 };
          case "ROP7":
            return { posX: 75, posY: 264 };
          case "ROP8":
            return { posX: 78, posY: 264 };
          case "ROT":
            return { posX: 82, posY: 263 };
          case "ROA10":
            return { posX: 82, posY: 263 };
          case "ROA11":
            return { posX: 75, posY: 262 };
        }
      }
    } else if (position === BabyBodyPositions.Breech) {
      switch (rotation) {
        case "LSA1":
          return { posX: 98, posY: 263 };
        case "LSA2":
          return { posX: 100, posY: 264 };
        case "LST":
          return { posX: 92, posY: 262 };
        case "LSP4":
          return { posX: 93, posY: 262 };
        case "LSP5":
          return { posX: 93, posY: 263 };
        case "SP":
          return { posX: 97, posY: 264 };
        case "RSP7":
          return { posX: 55, posY: 263 };
        case "RSP8":
          return { posX: 57, posY: 262 };
        case "RST":
          return { posX: 60, posY: 262 };
        case "RSA10":
          return { posX: 50, posY: 265 };
        case "RSA11":
          return { posX: 50, posY: 264 };
        default:
          // Position X & Y for the default image in SeteFotLeie
          return { posX: 68, posY: 262 };
      }
    }
    return { posX, posY };
  };

  // Gets the initial position coordinates for baby head images based on position type
  // Returns an object with x,y coordinates
  static getBabyHeadImagesInitialPosition = (position: string) => {
    switch (position) {
      case BabyHeadPositions.Centered:
        return { posX: 137, posY: 98 };
      case BabyHeadPositions.FrontAsynclitic:
      case BabyHeadPositions.BackAsynclitic:
        return { posX: 123, posY: 90 };
      case BabyHeadPositions.Breech:
        return { posX: 112, posY: 90 };
      default:
        return { posX: 137, posY: 98 };
    }
  };

  // Calculates the angle based on baby's body position (Cephalic/Breech) and rotation code
  // Returns the calculated angle as a number
  static getAngleByBodyPositionAndBodyRotation = (
    babyPosition: string,
    babyRotation: string
  ) => {
    const angle =
      babyPosition === BabyBodyPositions.Cephalic
        ? Global.getKeyFromObjectValue(
            Dictionaries.rotationLabelInternalValuesByAngleAndCephalicPosition,
            babyRotation
          )
        : Global.getKeyFromObjectValue(
            Dictionaries.rotationLabelInternalValuesByAngleAndBreechPosition,
            babyRotation
          );
    return Number(angle);
  };

  // Determines the baby's head position based on the selected position and rotation angle
  // Returns the calculated head position as a string
  static getBabyHeadPositionBasedOnAngle = (
    selectedPosition: string,
    angle: number
  ): string => {
    const isPosteriorRotation = angle >= 180 && angle < 360;

    switch (selectedPosition) {
      case BabyHeadPositions.FrontAsynclitic:
      case BabyHeadPositions.BackAsynclitic:
        return isPosteriorRotation
          ? selectedPosition
          : selectedPosition === BabyHeadPositions.FrontAsynclitic
          ? BabyHeadPositions.BackAsynclitic
          : BabyHeadPositions.FrontAsynclitic;
      case BabyHeadPositions.Breech:
        return BabyHeadPositions.Breech;
      default:
        return BabyHeadPositions.Centered;
    }
  };
}
