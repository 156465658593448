import { useEffect, useState } from "react";
import {
  BasicDropdown,
  Button,
  Size,
  ModalDialog,
  useToast,
} from "@laerdal/life-react-components";
import {
  LocalStorageKey,
  removeKeyFromLocalStorage,
  useLocalStorage,
} from "../helpers/LocalStorage";
import SlowProgressCalculation from "../helpers/SlowProgressCalculation";
import DropDownDatasources from "../helpers/DropDownDatasources";
import Global from "../helpers/Global";
import { ToastMessageType } from "../helpers/Enums";
import DateTimePicker from "./DateTimePicker";
import dayjs from "dayjs";
import useLabourViewStore from "../hooks/useLabourViewStore";
import "../../resources/styles/Calculator.css";

const Calculator = () => {
  // Variables

  // Stores the calculated slow progress time.
  const [slowProgressTime, setSlowProgressTime] = useLocalStorage(
    LocalStorageKey.SlowProgressTime,
    "--:--"
  );

  // Stores the last registered time value.
  const [registeredLatestTime, setRegisteredLatestTime] = useLocalStorage(
    LocalStorageKey.RegisteredLatestTime,
    ""
  );

  // Represents the currently selected time from the date-time picker.
  const [selectedLatestTime, setSelectedLatestTime] = useState(
    registeredLatestTime || dayjs()
  );

  // Keeps track of the last recorded cervix opening value.
  const [registeredCervixOpening, setRegisteredCervixOpening] = useLocalStorage(
    LocalStorageKey.RegisteredCervixOpening,
    ""
  );

  // Holds the currently selected cervix opening value from the dropdown.
  const [selectedCervixOpening, setSelectedCervixOpening] = useState(
    registeredCervixOpening
  );

  // Stores additional hours calculated for the slow progress time.
  const [addedHoursToSlowProgress, setAddedHoursToSlowProgress] =
    useLocalStorage(LocalStorageKey.AddedHoursToSlowProgress, "");

  // Manages whether to remove focus from the time input field.
  const [removeFocusFromTimeInput, setRemoveFocusFromTimeInput] =
    useState(false);

  // ID of the currently open toast notification.
  const [currentOpenToastId, setCurrentOpenToastId] = useState("");

  // Controls visibility of the tidspunkt confirmation modal.
  const [openTidspunktConfirmationModal, setOpenTidspunktConfirmationModal] =
    useState(false);

  // Contains cervix opening values for the dropdown.
  const cervixOpeningValues = DropDownDatasources.getCervixOpeningShortValues();

  // State manager for resetting the calculator data.
  const resetCalculatorData = useLabourViewStore(
    (state) => state.resetCalculatorData
  );
  const setResetCalculatorData = useLabourViewStore(
    (state) => state.setResetCalculatorData
  );

  // Toast management hooks
  const { addToast, removeToast } = useToast();

  // Functions

  // Performs the calculation for slow progress time using the current cervix opening and time values.
  const ExecuteSlowProgressTimeCalculation = () => {
    // Calculate slow progress value and additional hours based on current cervix opening and time
    const [slowProgressValue, addedHours] =
      SlowProgressCalculation.calculateCurrentSlowProgressValue(
        selectedCervixOpening,
        selectedLatestTime
      );

    // Update the stored cervix opening and latest time values
    setRegisteredCervixOpening(selectedCervixOpening);
    setRegisteredLatestTime(dayjs(selectedLatestTime).format("HH:mm"));
    setSlowProgressTime(slowProgressValue);
    setAddedHoursToSlowProgress(addedHours);

    // Remove focus from the time input field to force update
    setRemoveFocusFromTimeInput(true);

    // Display a success message indicating that the slow progress time has been updated
    return Global.showMessageBox(
      addToast,
      "Tidspunkt for langsom fremgang er endret"
    );
  };

  // Validate inputs, perform calculations, and handle display of error or confirmation messages
  const CalculateDelayedProgressTimeValue = () => {
    let toastId = "";

    // Remove any previously displayed toast notification
    removeToast(currentOpenToastId);

    // Validate inputs before performing calculations
    const validationResults =
      SlowProgressCalculation.validateInputsBeforeCalculation(
        selectedCervixOpening,
        registeredCervixOpening,
        selectedLatestTime,
        registeredLatestTime
      );

    if (!validationResults) {
      toastId = ExecuteSlowProgressTimeCalculation();
    } else {
      // Display appropriate message based on validation results
      switch (validationResults.messageType) {
        case ToastMessageType.Info:
        case ToastMessageType.Warning:
        case ToastMessageType.Error:
          toastId = Global.showMessageBox(
            addToast,
            validationResults.message,
            validationResults.messageType
          );
          break;
        case "tidspunkt-confirmation":
          // Open confirmation modal for ambiguous results
          setOpenTidspunktConfirmationModal(true);
          break;
      }
    }

    // Update the state with the ID of the current toast notification
    setCurrentOpenToastId(toastId);
  };

  // Returns the label for the first button in the confirmation modal, showing
  // the registered cervix opening, time of measurement, and calculated slow progress time
  const GetTidspunktModalConfirmationButtonOneLabel = () => {
    return (
      <span className="tidspunkt-confirmation-window-button-text">
        {registeredCervixOpening} cm målt {registeredLatestTime} →
        <span className="slow-progress-time"> {slowProgressTime}</span>
      </span>
    );
  };

  // Returns the label for the second button in the confirmation modal, showing
  // the selected cervix opening, the selected time formatted, and the newly calculated slow progress time
  const GetTidspunktModalConfirmationButtonTwoLabel = () => {
    // Calculate the new slow progress value based on the selected cervix opening and time
    const [newPossibleSlowProgressValue] =
      SlowProgressCalculation.calculateCurrentSlowProgressValue(
        selectedCervixOpening,
        selectedLatestTime
      );
    return (
      <span className="tidspunkt-confirmation-window-button-text">
        {selectedCervixOpening} cm målt{" "}
        {dayjs(selectedLatestTime).format("HH:mm")} →
        <span className="slow-progress-time">
          {" "}
          {newPossibleSlowProgressValue}
        </span>
      </span>
    );
  };

  // Updates slow progress values and closes the confirmation modal
  const SetNewSlowProgressCalculatedValues = () => {
    // Execute the calculation and get the toast ID for the success message
    let toastId = ExecuteSlowProgressTimeCalculation();

    // Store the current toast ID in the state and close the confirmation modal
    setCurrentOpenToastId(toastId);
    setOpenTidspunktConfirmationModal(false);
  };

  useEffect(() => {
    // Check if reset has been triggered
    if (resetCalculatorData) {
      // Clear relevant keys from local storage to reset state
      // INFO: Not using localStorage.clear() since it does not clear cached values in local storage
      // and values comes back randomly when refreshing page or resetting new patient
      removeKeyFromLocalStorage(LocalStorageKey.SlowProgressTime);
      removeKeyFromLocalStorage(LocalStorageKey.RegisteredLatestTime);
      removeKeyFromLocalStorage(LocalStorageKey.RegisteredCervixOpening);
      removeKeyFromLocalStorage(LocalStorageKey.InputWithFocusTimestamp);
      removeKeyFromLocalStorage(LocalStorageKey.AddedHoursToSlowProgress);

      // Reset component state to initial values
      setSelectedCervixOpening("");
      setSelectedLatestTime("");
      setSlowProgressTime("--:--");
      setRegisteredCervixOpening("");
      setRegisteredLatestTime("");
      setAddedHoursToSlowProgress("");
      setRemoveFocusFromTimeInput(true);

      // Remove any active toast notifications to avoid clutter
      removeToast(currentOpenToastId);

      // Update global reset state indicating that the reset operation has been completed
      setResetCalculatorData(false);
    }
  }, [
    resetCalculatorData,
    currentOpenToastId,
    removeToast,
    setAddedHoursToSlowProgress,
    setRegisteredCervixOpening,
    setRegisteredLatestTime,
    setSlowProgressTime,
    setResetCalculatorData,
  ]);

  return (
    <>
      <div className="calculator-container">
        <div className="main-calculator-input-container">
          <div className="main-input-container">
            {/* Dropdown for selecting cervix opening */}
            <div className="input-container">
              <span className="input-title">Mormunnsåpning</span>
              <BasicDropdown
                id="basicDropdown"
                list={cervixOpeningValues}
                value={selectedCervixOpening}
                size={Size.Small}
                onSelect={(value) => setSelectedCervixOpening(value)}
                minWidth="230px"
              />
            </div>
            {/* DateTimePicker for selecting the time of vaginal examination */}
            <div className="input-container">
              <span className="input-title">
                Tidspunkt for vaginal undersøkelse
              </span>
              <DateTimePicker
                removeFocusFromTimeInput={removeFocusFromTimeInput}
                setRemoveFocusInParentComponent={setRemoveFocusFromTimeInput}
                setSelectedLatestTime={setSelectedLatestTime}
                canPickFutureDateTime={false}
              />
            </div>
          </div>
          {/* Button to trigger calculation */}
          <div className="button-container">
            <Button
              size={Size.Medium}
              width="230px"
              onClick={() => CalculateDelayedProgressTimeValue()}
            >
              Vis tidspunkt
            </Button>
          </div>
        </div>
        {/* Display calculation results */}
        <div className="calculator-result-container">
          <div className="main-title">Tidspunkt for langsom fremgang</div>
          <div className="delayed-progress-time-value">{slowProgressTime}</div>
          {slowProgressTime !== "--:--" && (
            <div className="more-details">
              <div className="first-line">
                <span className="cervix-opening-value">
                  {addedHoursToSlowProgress || "-"} timer
                </span>
                {" etter "}
                <span className="cervix-opening-value">
                  {registeredCervixOpening || "-"} cm
                </span>
                {" ble "}
              </div>
              <div className="second-line">
                {"målt første gang "}
                <span className="latest-time-registered-value">
                  {registeredLatestTime || "--:--"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal dialog for confirmation */}
      <ModalDialog
        key="ConfirmationModalDialog"
        title="Tidspunkt for langsom fremgang ikke definert"
        size={Size.Medium}
        isModalOpen={openTidspunktConfirmationModal}
        closeAction={() => {
          setOpenTidspunktConfirmationModal(false);
        }}        
        shouldCloseOnOverlayClick={false}
        submitAction={() => {}}
        buttons={[
          {
            action: () => {
              // Close modal
              setOpenTidspunktConfirmationModal(false);
            },
            variant: "secondary",
            children: GetTidspunktModalConfirmationButtonOneLabel(),
          },
          {
            action: () => {
              // Set new values and close modal
              SetNewSlowProgressCalculatedValues();
            },
            variant: "secondary",
            children: GetTidspunktModalConfirmationButtonTwoLabel(),
          },
        ]}
      >
        <span className="tidskpunt-confirmation-window-body">
          WHOs Labour Care Guide definerer ikke tidspunkt for langsom fremgang
          når mormunnsåpningen blir mindre over tid. Hvilken kombinasjon av
          mormunnsåpning og tidspunkt vil du bruke?
        </span>
      </ModalDialog>
    </>
  );
};

export default Calculator;
