// Cephalic centered imports
import { ReactComponent as CCOA } from "../../resources/images/baby-body/cephalic/centered/OA.svg";
import { ReactComponent as CCOP } from "../../resources/images/baby-body/cephalic/centered/OP.svg";
import { ReactComponent as CCLOA1 } from "../../resources/images/baby-body/cephalic/centered/LOA1.svg";
import { ReactComponent as CCLOA2 } from "../../resources/images/baby-body/cephalic/centered/LOA2.svg";
import { ReactComponent as CCLOP4 } from "../../resources/images/baby-body/cephalic/centered/LOP4.svg";
import { ReactComponent as CCLOP5 } from "../../resources/images/baby-body/cephalic/centered/LOP5.svg";
import { ReactComponent as CCLOT } from "../../resources/images/baby-body/cephalic/centered/LOT.svg";

// Cephalic front asynclitic imports
import { ReactComponent as CFLOA1 } from "../../resources/images/baby-body/cephalic/front-asynclitic/LOA1.svg";
import { ReactComponent as CFLOA2 } from "../../resources/images/baby-body/cephalic/front-asynclitic/LOA2.svg";
import { ReactComponent as CFLOP4 } from "../../resources/images/baby-body/cephalic/front-asynclitic/LOP4.svg";
import { ReactComponent as CFLOP5 } from "../../resources/images/baby-body/cephalic/front-asynclitic/LOP5.svg";
import { ReactComponent as CFLOT } from "../../resources/images/baby-body/cephalic/front-asynclitic/LOT.svg";

// Cephalic back asynclitic imports
import { ReactComponent as CBLOA1 } from "../../resources/images/baby-body/cephalic/back-asynclitic/LOA1.svg";
import { ReactComponent as CBLOA2 } from "../../resources/images/baby-body/cephalic/back-asynclitic/LOA2.svg";
import { ReactComponent as CBLOP4 } from "../../resources/images/baby-body/cephalic/back-asynclitic/LOP4.svg";
import { ReactComponent as CBLOP5 } from "../../resources/images/baby-body/cephalic/back-asynclitic/LOP5.svg";
import { ReactComponent as CBLOT } from "../../resources/images/baby-body/cephalic/back-asynclitic/LOT.svg";

// Breech imports
import { ReactComponent as BSA } from "../../resources/images/baby-body/breech/SA.svg";
import { ReactComponent as BSP } from "../../resources/images/baby-body/breech/SP.svg";
import { ReactComponent as BLSA1 } from "../../resources/images/baby-body/breech/LSA1.svg";
import { ReactComponent as BLSA2 } from "../../resources/images/baby-body/breech/LSA2.svg";
import { ReactComponent as BLSP4 } from "../../resources/images/baby-body/breech/LSP4.svg";
import { ReactComponent as BLSP5 } from "../../resources/images/baby-body/breech/LSP5.svg";
import { ReactComponent as BLST } from "../../resources/images/baby-body/breech/LST.svg";

import { BabyBodyPositions, BabyHeadPositions } from "../helpers/Enums";

interface Props {
  position: string;
  rotation: string;
  synclitism: string;
}

// About the acronyms: they are related to fetal positioning or belly mapping
// http://www.earthboundtherapeutics.com/blog/fetal-positioning-aka-belly-mapping
// The initial C is for Cephalic (Hodeleie) and the B is for Breech (Sete/Foteleie)
const babyBodyImages: { [key: string]: React.ComponentType } = {
  BSA,
  BSP,
  BLSA1,
  BLSA2,
  BLSP4,
  BLSP5,
  BLST,
  // Cephalic centered
  CCOA,
  CCOP,
  CCLOA1,
  CCLOA2,
  CCLOP4,
  CCLOP5,
  CCLOT,
  // Cephalic front asynclitic
  CFLOA1,
  CFLOA2,
  CFLOP4,
  CFLOP5,
  CFLOT,
  // Cephalic back asynclitic
  CBLOA1,
  CBLOA2,
  CBLOP4,
  CBLOP5,
  CBLOT,
};

const BabyBody = ({ position, rotation, synclitism }: Props) => {
  const shortPosition = position === BabyBodyPositions.Cephalic ? "C" : "B";
  const isRightSide = rotation.startsWith("R");

  // Convert right-side rotations to their left-side equivalents
  const mappedRotation = isRightSide
    ? rotation
        .replace("ROA11", "LOA1")
        .replace("ROA10", "LOA2")
        .replace("ROP8", "LOP4")
        .replace("ROP7", "LOP5")
        .replace("ROT", "LOT")
        // Breech mappings
        .replace("RSA11", "LSA1")
        .replace("RSA10", "LSA2")
        .replace("RSP8", "LSP4")
        .replace("RSP7", "LSP5")
        .replace("RST", "LST")
    : rotation;

  // Only add synclitism for Cephalic position
  const imageKey =
    shortPosition === "C"
      ? shortPosition +
        (synclitism === BabyHeadPositions.Centered
          ? "C"
          : synclitism === BabyHeadPositions.FrontAsynclitic
          ? "F"
          : "B") +
        mappedRotation
      : shortPosition + mappedRotation;

  const BabyBodyComponent = babyBodyImages[imageKey];
  return <BabyBodyComponent />;
};

export default BabyBody;
