import { create } from "zustand";
import {
  BabyBodyPositions,
  BabyHeadPositions,
  CephalicPositions,
} from "../helpers/Enums";

// Define the structure of the Labour View store's state
interface LabourViewStoreState {
  // Resets calculator data when 'avslutt' button is clicked
  resetCalculatorData: boolean;
  setResetCalculatorData: (value: boolean) => void;

  // Resets visualization values when 'avslutt' button is clicked
  resetVisualizationsData: boolean;
  setResetVisualizationsData: (value: boolean) => void;

  // Baby head position for descent and rotation visualizations
  babyHeadPosition: string;
  setBabyHeadPosition: (value: string) => void;

  // Baby body position for descent and rotation visualizations
  babyBodyPosition: string;
  setBabyBodyPosition: (value: string) => void;

  // Baby body rotation for descent and rotation visualizations
  babyBodyRotation: string;
  setBabyBodyRotation: (value: string) => void;

  // Cervix opening for descent and rotation visualizations
  cervixOpening: string;
  setCervixOpening: (value: string) => void;

  // Cervix descent level for descent visualization
  babyDescentLevel: number;
  setBabyDescentLevel: (value: number) => void;

  // Triggers refresh of labour data from storage
  refreshLabourProgressDataFromStorage: boolean;
  setRefreshLabourProgressDataFromStorage: (value: boolean) => void;

  // Controls progress player mode and overlay
  isPlayerModeActive: boolean;
  setIsPlayerModeActive: (value: boolean) => void;

  // Controls date-time picker active state and overlay
  isDateTimePickerActive: boolean;
  setIsDateTimePickerActive: (value: boolean) => void;
}

// Create and export the Labour View Zustand store with initial state
const useLabourViewStore = create<LabourViewStoreState>((set) => ({
  // Reset calculator data state
  resetCalculatorData: false,
  setResetCalculatorData: (value: boolean) =>
    set({ resetCalculatorData: value }),

  // Reset visualizations data state
  resetVisualizationsData: false,
  setResetVisualizationsData: (value: boolean) =>
    set({ resetVisualizationsData: value }),

  // Baby head position state
  babyHeadPosition: BabyHeadPositions.Centered,
  setBabyHeadPosition: (value: string) => set({ babyHeadPosition: value }),

  // Baby body position state
  babyBodyPosition: BabyBodyPositions.Cephalic,
  setBabyBodyPosition: (value: string) => set({ babyBodyPosition: value }),

  // Baby body rotation state
  babyBodyRotation: CephalicPositions.OcciputAnterior,
  setBabyBodyRotation: (value: string) => set({ babyBodyRotation: value }),

  // Cervix opening state
  cervixOpening: "1",
  setCervixOpening: (value: string) => set({ cervixOpening: value }),

  // Baby descent level state
  babyDescentLevel: -5,
  setBabyDescentLevel: (value: number) => set({ babyDescentLevel: value }),

  // Refresh labour progress data state
  refreshLabourProgressDataFromStorage: false,
  setRefreshLabourProgressDataFromStorage: (value: boolean) =>
    set({ refreshLabourProgressDataFromStorage: value }),

  // Player mode active state
  isPlayerModeActive: false,
  setIsPlayerModeActive: (value: boolean) => set({ isPlayerModeActive: value }),

  // Date-time picker active state
  isDateTimePickerActive: false,
  setIsDateTimePickerActive: (value: boolean) =>
    set({ isDateTimePickerActive: value }),
}));

export default useLabourViewStore;
