import { useEffect, useState, useMemo, useCallback } from "react";
import {
  BasicDropdown,
  DropdownContentContainer,
  Size,
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {
  BabyBodyPositions,
  BabyHeadPositions,
  BreechPositions,
  CephalicPositions,
} from "../helpers/Enums";
import DropDownDatasources from "../helpers/DropDownDatasources";
import {
  LocalStorageKey,
  removeKeyFromLocalStorage,
} from "../helpers/LocalStorage";
import useLabourViewStore from "../hooks/useLabourViewStore";
import CervixDescent from "./CervixDescent";
import RotationAndOpening from "./RotationAndOpening";
import "../../resources/styles/Visualizations.css";

// Styled component for setting minimum dropdown width
const DescentDropDownContainer = styled.div`
  ${DropdownContentContainer} {
    min-width: 223px;
  }
`;

const Visualizations = () => {
  // Dropdown options
  const descentPositionValues = DropDownDatasources.getDescentPosition();
  const cervixOpeningValues = DropDownDatasources.getCervixOpeningFullValues();

  // Global state manager
  const {
    // Cephalic or Breech presentation
    babyBodyPosition,     
    setBabyBodyPosition,
    // Baby's asynclitic position (Centered/Left/Right/Breech)
    babyHeadPosition,     
    setBabyHeadPosition,
    // Cervical dilation in centimeters
    cervixOpening,        
    setCervixOpening,
    // Rotation angle of baby's presentation
    setBabyBodyRotation,
    // Baby's descent level (-5 to +5)    
    setBabyDescentLevel,  
    resetVisualizationsData, 
    setResetVisualizationsData, 
    isPlayerModeActive: isPlayerInProgress,
  } = useLabourViewStore((state) => state);

  // Tracks current rotation for asynclitic position constraints
  const [currentRotationAngle, setCurrentRotationAngle] = useState(0);

  // Disable Left/Right asynclitic positions at 0° and 180° (direct anterior/posterior)
  const asyncliticPositionValues = useMemo(
    () =>
      DropDownDatasources.getAsyncliticPositionValues().map((option) => ({
        ...option,
        disabled:
          option.value !== BabyHeadPositions.Centered &&
          (currentRotationAngle === 0 || currentRotationAngle === 180),
      })),
    [currentRotationAngle]
  );

  // Handles rotation changes and enforces centered position at 0° and 180°
  const handleRotationChange = useCallback(
    (angle: number) => {
      // Normalize angle to 0-359 range. This also handles negative angles 
      // turning them into their positive equivalents.
      const normalizedAngle = ((angle % 360) + 360) % 360;
      setCurrentRotationAngle(normalizedAngle);

      if ((normalizedAngle === 0 || normalizedAngle === 180) && babyBodyPosition !== BabyBodyPositions.Breech) {
        setBabyHeadPosition(BabyHeadPositions.Centered);
      }
    },
    [setBabyHeadPosition, babyBodyPosition]
  );

  // Updates baby presentation and adjusts related positions
  const handleBodyPositionChange = useCallback(
    (value: string) => {
      const position = value as BabyBodyPositions;
      setBabyBodyPosition(position);

      // Set appropriate head position and rotation based on presentation
      if (position === BabyBodyPositions.Cephalic) {
        setBabyHeadPosition(BabyHeadPositions.Centered);
        setBabyBodyRotation(CephalicPositions.OcciputAnterior);
      } else if (position === BabyBodyPositions.Breech) {
        setBabyHeadPosition(BabyHeadPositions.Breech);
        setBabyBodyRotation(BreechPositions.SacrumAnterior);
      }
    },
    [setBabyBodyPosition, setBabyHeadPosition, setBabyBodyRotation]
  );

  // Reset handler for new patient data
  useEffect(() => {
    if (resetVisualizationsData) {
      // Clear stored progress data
      removeKeyFromLocalStorage(LocalStorageKey.LabourProgressDataArray);

      // Reset all visualization parameters to defaults
      setBabyBodyPosition(BabyBodyPositions.Cephalic);
      setBabyBodyRotation(CephalicPositions.OcciputAnterior);
      setBabyHeadPosition(BabyHeadPositions.Centered);
      setBabyDescentLevel(-5);
      setCervixOpening("5");

      setResetVisualizationsData(false);
    }
  }, [
    resetVisualizationsData,
    setBabyBodyPosition,
    setBabyBodyRotation,
    setBabyDescentLevel,
    setBabyHeadPosition,
    setCervixOpening,
    setResetVisualizationsData,
  ]);

  return (
    // Apply higher z-index when player is active to ensure visualizations stay on top
    <div className={isPlayerInProgress ? "z-index-to-top" : ""}>
      <DescentDropDownContainer>
        {/* Main container for both descent and rotation visualizations */}
        <div className="visualizations-container">
          {/* Left side: Descent level visualization */}
          <div className="descent-visualization-main-container">
            <span className="title">Nivå</span>
            <div className="inner-container">
              {/* Controls for descent position (Cephalic/Breech) */}
              <div className="top-bar-container">
                <div className="dropdown-container">
                  <BasicDropdown
                    id="basicDropdown"
                    list={descentPositionValues}
                    value={babyBodyPosition}
                    size={Size.Small}
                    onSelect={handleBodyPositionChange}
                    minWidth="223px"
                    disabled={isPlayerInProgress}
                  />
                </div>
              </div>
              {/* Visual representation of cervical descent */}
              <div className="image-container">
                <CervixDescent />
              </div>
            </div>
          </div>

          {/* Right side: Rotation and cervical opening visualization */}
          <div className="rotation-visualization-main-container">
            <span className="title">Posisjon og mormunnsåpning</span>
            <div className="inner-container">
              {/* Controls for asynclitic position and cervix dilation */}
              <div className="top-bar-container">
                {/* Conditionally render the asynclitic position dropdown */}
                {babyHeadPosition !== BabyHeadPositions.Breech && (
                  <div className="dropdown-container">
                    <BasicDropdown
                      id="asynclitic-position-dropdown"
                      list={asyncliticPositionValues}
                      value={babyHeadPosition}
                      size={Size.Small}
                      onSelect={setBabyHeadPosition}
                      minWidth="223px"
                      disabled={isPlayerInProgress}
                    />
                  </div>
                )}
                {/* Cervical opening dropdown (dilation in cm) */}
                <div className="dropdown-container">
                  <BasicDropdown
                    id="cervix-opening-dropdown"
                    list={cervixOpeningValues}
                    value={cervixOpening}
                    size={Size.Small}
                    onSelect={setCervixOpening}
                    minWidth="223px"
                    disabled={isPlayerInProgress}
                  />
                </div>
              </div>
              {/* Visual representation of rotation and cervical opening */}
              <div className="image-container">
                <RotationAndOpening onRotationChange={handleRotationChange} />
              </div>
            </div>
          </div>
        </div>
      </DescentDropDownContainer>
    </div>
  );
};

export default Visualizations;
